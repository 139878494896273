<template>
  <div class="'Scripta'">
    <PageTitle title="Scripta" />
    <b-container fluid="xxl">
      <b-row>
        <b-col cols="8">
          <p><b><i>Analyses scriptologiques des DocLing</i></b><br><br>
          dirigée par Martin Glessgen et Paul Videsott<br/>
          en collaboration avec Cristina Dusio, Jessica Meierhofer, Jonathan Schaber et Marco Robecchi<br/>
          Première version à paraître en mai 2024
          </p>
          <p>Le projet des <i>Analyses scriptologiques des DocLing</i> (Scripta) prend appui sur un balisage grapho-phonétique et, dans une moindre mesure, morphologique des différents corpus des DocLing. Il se concentre sur les <i>scriptae</i> oïliques, en incluant toutefois les territoires de l’<i>Occitania submersa</i> des régions du Poitou et des Charentes ainsi que du domaine francoprovençal (qui connaît une <i>scripta</i> mixte français-francoprovençal, surtout au nord de Lyon et en Suisse romande). De manière non systématique, il prend également en considération les corpus occitans des DocLing.</p>
          <p>Les balisages ont été entrepris dès la première version des DocLing (2009) et ils ont été fortement élargis depuis 2021 dans le cadre d'un projet du Fonds National Suisse et de la région du Tyrol du Sud (<i>La variation linguistique médiévale en tant que système</i>). Parallèlement à la structuration des différents paramètres phonétiques et morphologiques, le projet entend développer une représentation cartographique des données en vue d'un atlas scriptologique galloroman. Ce dernier sera enrichi par la comparaison avec les atlas de l'ancien français d'Antonij Dees et de Piet Van Reenen dont la structuration des paramètres a été prise en considération dans le détail.<br/>
          Le projet est actuellement en cours et prévoit une première mise en ligne au courant de l'année 2024.</p>
          <!-- <router-link :to="{ name: 'LemmaSearch' }" class="btn btn-secondary btn-legam mt-4 mr-1">
            Explore Scripta
          </router-link> -->
          <br>
          <a href="/pdfs/further-information.pdf" class="btn btn-secondary btn-legam mt-4">
            <svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="file pdf fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-file-pdf-fill mx-auto b-icon bi" data-v-41be6633=""><g data-v-41be6633=""><path d="M5.523 10.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.035 21.035 0 0 0 .5-1.05 11.96 11.96 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.888 3.888 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 4.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"></path><path fill-rule="evenodd" d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm.165 11.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.64 11.64 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.707 19.707 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"></path></g></svg>
            {{ $t('FurtherInformation') }}
          </a>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
</style>

<script>
  import PageTitle from '@/components/PageTitle.vue'

  export default {
    name: 'Scripta',
    data() {
      return {

      }
    },
    components: {
      PageTitle,
    },
    computed: {
    },
    mounted() {
    },
    watch: {
    },
    methods: {
    }
  }
</script>
